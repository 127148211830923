<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      v-if="order"
    >
      <md-card>
        <md-card-header data-background-color="blue" class="card-header">
          <div class="card-header-info">
            <h4 class="title">Заказ</h4>
          </div>
        </md-card-header>
        <md-card-content>
          <md-table>
            <md-table-row>
              <md-table-cell md-label="ID" class="custom-field">
                <span>ID:</span>
              </md-table-cell>
              <md-table-cell md-label="ID" class="custom-field">
                <span>{{ order.id }}</span>
              </md-table-cell>

            </md-table-row>
            <md-table-row>
              <md-table-cell md-label="ID" class="custom-field">
                <span>Дата заказа:</span>
              </md-table-cell>
              <md-table-cell md-label="ID" class="custom-field">
                <span>{{ formatISODate(order.order.created_at) }}</span>
              </md-table-cell>

            </md-table-row>
            <md-table-row>
              <md-table-cell md-label="ID" class="custom-field">
                <span>Сумма заказа:</span>
              </md-table-cell>
              <md-table-cell md-label="ID" class="custom-field">
                <span>{{ sumOrders(order.order.order_product) }}</span>
              </md-table-cell>

            </md-table-row>
            <md-table-row>
              <md-table-cell md-label="ID" class="custom-field">
                <span>Сумма возврата:</span>
              </md-table-cell>
              <md-table-cell md-label="ID" class="custom-field">
                <span>{{ sumOrders(order.order_return_products) }}</span>
              </md-table-cell>

            </md-table-row>
            <md-table-row>
              <md-table-cell md-label="ID" class="custom-field">
                <span>ID пользователя:</span>
              </md-table-cell>
              <md-table-cell md-label="ID" class="custom-field">
                <span>{{ order.user.id }}</span>
              </md-table-cell>

            </md-table-row>
            <md-table-row>
              <md-table-cell md-label="ID" class="custom-field">
                <span>Имя пользователя:</span>
              </md-table-cell>
              <md-table-cell md-label="ID" class="custom-field">
                <span>{{ order.user.name }}</span>
              </md-table-cell>

            </md-table-row>
            <md-table-row>
              <md-table-cell md-label="ID" class="custom-field">
                <span>Адрес доставки:</span>
              </md-table-cell>
              <md-table-cell md-label="ID" class="custom-field">
                <span>{{ order.order.delivery_address }}</span>
              </md-table-cell>

            </md-table-row>

            <md-table-row v-if="order.track_number">
              <md-table-cell md-label="ID" class="custom-field">
                <span>Трек номер заказа:</span>
              </md-table-cell>
              <md-table-cell md-label="ID" class="custom-field">
                <span>{{ order.track_number }}</span>
              </md-table-cell>

            </md-table-row>
          </md-table>
        </md-card-content>
      </md-card>
      <!--      <md-card>-->
      <!--        <md-card-header data-background-color="blue" class="card-header">-->
      <!--          <div class="card-header-info">-->
      <!--            <h4 class="title">Товары в заказе:</h4>-->
      <!--          </div>-->
      <!--        </md-card-header>-->
      <!--        <md-card-content>-->
      <!--            <md-table>-->

      <!--            </md-table>-->
      <!--        </md-card-content>-->
      <!--      </md-card>-->
      <md-card>
        <md-card-header data-background-color="blue" class="card-header">
          <div class="card-header-info">
            <h4 class="title">Товары на возврат:</h4>
          </div>
        </md-card-header>
        <md-card-content>
          <ProductCell
            v-for="item in order.order_return_products"
            :item="item"
          />
        </md-card-content>
      </md-card>
      <md-card
        v-if="order.messages.length"
      >
        <md-card-header data-background-color="blue" class="card-header">
          <div class="card-header-info">
            <h4 class="title">Сообщения:</h4>
          </div>
        </md-card-header>
        <md-card-content>
          <md-table>
            <template>
              <md-table-row
                v-for="message in order.messages"
                :key="message.id"
              >
                <md-table-cell md-label="ID" class="custom-field">
                  <span>{{ message.user ? 'Клиент' : 'Администратор' }}:</span>
                </md-table-cell>
                <md-table-cell md-label="ID" class="custom-field">
                  {{ message.message }}
                </md-table-cell>
              </md-table-row>
            </template>
          </md-table>
        </md-card-content>
      </md-card>
      <md-card
        v-if="order.status === 'requested' || order.status === 'approved'"
      >
        <md-card-header data-background-color="blue" class="card-header">
          <div class="card-header-info">
            <h4 class="title">Возможные действия:</h4>
          </div>
        </md-card-header>
        <md-card-content>
          <div>
            <md-button
              v-if="order.status === 'requested'"
              @click="handleSetStatus('approved')"
              class="md-primary"
            >
              Одобрить
            </md-button>
            <md-button
              v-if="order.status === 'requested'"
              @click="showModal = true"
              class="md-primary"
            >
              Отклонить
            </md-button>
            <md-button
              v-if="order.status === 'approved'"
              @click="handleSetStatus('paid')"
              class="md-primary"
            >
              Оплатить возврат
            </md-button>
          </div>
        </md-card-content>
      </md-card>

    </div>
    <div v-else class="my-empty empty">Пусто</div>
    <ModalReject
      v-if="showModal"
      @close="showModal = false"
      @confirm="text => handleReject(text)"
    />
  </div>
</template>

<script>
import {formatISODate} from "@/helpers/formatDate";
import {mapActions, mapState} from "vuex";
import ProductCell from "@/pages/ReturnOrder/ProductCell.vue";
import ModalReject from "@/pages/ReturnOrder/ModalReject.vue";

export default {
  name: "Order-Info",
  components: {ModalReject, ProductCell},
  data() {
    return {
      showModal: false,
    }
  },
  methods: {
    ...mapActions("returnPage", ["setStatus"]),
    formatISODate,
    sumOrders(productArray) {
      return productArray.reduce((prev, next) => {
        return prev + +next.price
      }, 0);
    },
    handleReject(text) {
      this.handleSetStatus('rejected', text);
      this.showModal = false;
    },
    async handleSetStatus(status, message) {
      this.$store.commit("SET_SHOW_LOADER", true);

      await this.setStatus({
        id: this.order.id,
        status: status,
        message: message,
      }).then(() => {
      })

      this.$store.commit("SET_SHOW_LOADER", false);
    },
  },
  computed: {
    ...mapState("returnPage", ["order",]),
  },
}
</script>

<style lang="scss" scoped>

.md-layout {
  max-width: 100%;
}

.md-primary {
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }
}
</style>
