<template>
  <transition name="modal-fade">
    <div ref="modal" class="modal-backdrop" @click="clickOutside">
      <div class="modal" role="dialog">
        <div class="btn-close" @click="close">
          <md-icon>close</md-icon>
        </div>

        <h4>Отклонить?</h4>

        <md-field>
          <label>Причина:</label>
          <md-textarea v-model="text" />
        </md-field>
        <div class="buttons-wrap">
          <md-button class="md-raised md-success" @click="$emit('confirm', text)">
            Подтвердить
          </md-button>
          <md-button class="md-raised md-danger" @click="close">
            Отменить
          </md-button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "ModalReject",
  data() {
    return {
      text: "",
    }
  },
  methods: {
    clickOutside(e) {
      if (e.target.classList.contains("modal-backdrop")) {
        this.$emit("close");
      }
    },

    close() {
      this.$emit("close");
    },
  }
}
</script>

<style lang="scss" scoped>
.modal-backdrop {
  position: fixed;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 0;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 10001;

  .modal {
    position: relative;
    height: auto;
    padding: 20px;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 5px;
    background: #fff;
  }

  .btn-close {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    font-size: 20px;
    color: #c4c4c4;
  }
}

.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}

.buttons-wrap {
  display: flex;

  & > * {
    &:first-child {
      margin-right: 8px;
    }

    &:last-child {
      margin-left: 8px;
    }
  }
}
</style>